import A11yDialog from 'a11y-dialog'; /*<-- import */

const container = document.querySelector('#my-dialog-container');
const dialog = new A11yDialog(container);
// console.log(container);

dialog.on('show', function(container, event){

})


